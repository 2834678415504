<template>
    <div class="OrderList">
        <el-page-header @back="back" :content="headerTitle"></el-page-header>
        <div class="f-w-b">
            <NavigationBar></NavigationBar>
            <div class="main">
                <div class="banner">
                    {{ enterpriseStateInfo.enterpriseName }}
                </div>

                <div class="screen-box f-c-b">
                    <div v-if="isScreen">筛选到：{{ total }} 条相关记录</div>
                    <div style="color: #333;font-size: 14px;font-weight: 500;" v-else>总条数：<span
                            style="font-size: 24px;color: #0363FA;">{{
                                total }}</span></div>
                    <div class="f-c">
                        <router-link class="addBtn" to="/AddOrder"
                            v-show="showAddOrderBtn && enterpriseStateInfo.userPermission.o_m == 'W'">
                        </router-link>
                        <div class="selectionBtn" @click="drawer = true">

                        </div>
                        <router-link class="statisticsBtn" :to="{
                            name: 'OrderStatistics', query: {
                                key: $UrlEncode.encode(JSON.stringify({
                                    type: queryType
                                }))
                            }
                        }" v-show="queryType != 'ReturnOrder'">
                        </router-link>
                    </div>
                </div>

                <ul class="f-c-b boxShadow mb-20" style="border-radius: 10px;">
                    <li class="ta-center liStyle-none f1 hover h-50" style="line-height: 50px;"
                        v-for="(item, index) of ulArr" :key="index"
                        :class="activeScan == index ? 'linearGradient' : 'bgEFF4FA'"
                        :style="{ 'borderRadius': index == 0 ? '10px 0 0 10px' : '0 10px 10px 0' }"
                        @click="HandleScan(index)">
                        {{ item.title }}
                        <i v-if="activeScan == index"
                            :class="item.sort == 1 ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
                    </li>
                </ul>
                <template v-if="orderList && orderList.length">
                    <div class="order-item f-w boxShadow" style="" v-for="(item, index) of orderList" :key="index"
                        @click="OrderDetail(item.orderId, item.orderStatus, item.orderReturnId)">
                        <div class="pl-15 pr-15 pt-10 f-w">
                            <div class="textVal">
                                客户名称：
                                <span class="textValSpan">{{ item.customerName }}</span>
                            </div>
                            <div class="textVal">
                                创建日期：
                                <span class="textValSpan">{{ item.createDate }}</span>
                            </div>
                            <div class="textVal">
                                {{ queryType == 'ReturnOrder' ? '退货单编号：' : '订单编号：' }}
                                <span class="textValSpan">{{ queryType == 'ReturnOrder' ? item.returnOrderNumber :
                                    item.orderNumber }}</span>
                            </div>
                            <div class="textVal">
                                更新日期：
                                <span class="textValSpan">{{ item.lastModifyDate }}</span>
                            </div>
                            <div class="textVal f-c">
                                订单状态：
                                <div style="width: 90px;height: 30px;text-align: center;line-height: 30px;border-radius: 10px;"
                                    :style="{ 'background': queryType == 'ReturnOrder' ? item.returnStatusbgColor : item.orderStatusbgColor }">
                                    <span v-if="queryType == 'ReturnOrder'" :style="{ color: item.returnStatusColor }">{{
                                        item.returnStatusText
                                    }}</span>
                                    <span v-else :style="{ color: item.orderStatusColor }">{{ item.orderStatusText }}</span>
                                </div>
                            </div>
                            <div class="textVal">
                                订单金额：
                                <span v-if="queryType == 'ReturnOrder'" style="color:red;">{{ item.valuationCurrency +
                                    item.returnRealityAmount2
                                }}</span>
                                <span v-else style="color:red;">{{ item.valuationCurrency + item.realityAmount2 }}</span>
                            </div>
                        </div>
                        <div class="w--100" >
                            <div class="f-w-b h-60 talTit">
                                <span>品名</span>
                                <span>编号</span>
                                <span>价格</span>
                                <span>数量</span>
                                <span>小计</span>
                                <span>备注</span>
                            </div>
                            <div v-if="item.orderDetailsList && item.orderDetailsList.length">
                                <div class="talVal f-w-b" v-for="(item1, index1) of item.orderDetailsList" :key="index1">
                                    <span>{{ item1.customerUseName }}</span>
                                    <span>{{ item1.customerUseNumber ? item1.customerUseNumber : '-'  }}</span>
                                    <span>{{ item1.unitPrice }}</span>
                                    <span>{{ item1.quantity }}</span>
                                    <span>{{ item1.amount }}</span>
                                    <span>{{ item1.note ? item1.note : '-' }}</span>
                                </div>
                            </div>
                            <div v-else class="f-c-c talVal">
                                暂无数据
                            </div>

                        </div>
                    </div>
                    <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
                        :pageSizes="pageSizes" @pagination="fetchData" />
                </template>
                <el-empty v-else description="暂无信息！"></el-empty>
            </div>
        </div>
        <el-drawer title="订单筛选" :visible="drawer" :direction="direction" class="drawer-box" :before-close="handleClose">
            <el-form ref="drawerForm" :model="drawerForm" class="drawer-form">
                <el-form-item v-for="(item, index) of drawerFormArr" :key="index" :label="item.label" :prop="item.prop">
                    <el-input v-if="item.isInput" v-model.trim="drawerForm[item.prop]" clearable
                        :placeholder="item.placeholderValue">
                    </el-input>
                    <el-date-picker v-if="item.isDate" v-model="drawerForm[item.prop]" type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss" :placeholder="item.placeholderValue">
                    </el-date-picker>
                    <el-select class="w--100" v-if="item.isSelect" v-model="drawerForm[item.prop]" :placeholder="item.placeholderValue" multiple>
                        <el-option v-for="(item,index) of orderStatusList" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="btns">
                    <el-button @click="resetForm('drawerForm')">重置</el-button>
                    <el-button type="primary" @click="submitFilter">提交</el-button>
                </el-form-item>
            </el-form>
        </el-drawer>
    </div>
</template>

<script>


import { PaginationRetention1 } from '@/mixins/PaginationRetentionMixin'
import { TableColumn4 } from '@/utils/TableColumn'

export default {
    name: 'OrderListInProgress',
    components:
    {
        NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
    },
    mixins: [PaginationRetention1],
    data() {
        return {
            headerTitle: '',//头部标题
            orderTypeStatus: '',//订单类型状态Active（未完成订单）、Completed（已完成） 传空代表全部
            drawer: false,//筛选盒子
            direction: 'rtl',
            pageSizes: [10, 20],
            drawerForm: {
                customerName: '',//客户名称名称
                orderNumber: '',//订单编号
                productName:'',//产品名称
                orderStatus:[],//订单状态
                createDateStart: '',//开始时间
                createDateEnd: '',//结束时间
            },
            drawerFormArr: [{
                label: "客户名称:",
                prop: "customerName",
                placeholderValue: "请输入客户名称",
                isInput: true
            }, {
                label: "订单编号：",
                prop: "orderNumber",
                placeholderValue: "请输入订单编号",
                isInput: true
            }, {
                label: "品名：",
                prop: "productName",
                placeholderValue: "请输入产品名称",
                isInput: true
            }, {
                label: "订单状态：",
                prop: "orderStatus",
                placeholderValue: "请选择订单状态",
                isSelect: true
            }, {
                label: "创建日期："
            }, {
                prop: "createDateStart",
                placeholderValue: "开始时间（订单创建时间）",
                isDate: true
            }, {
                prop: "createDateEnd",
                placeholderValue: "结束时间（订单创建时间）",
                isDate: true

            }],
            orderStatusList:[],
            TableColumn: TableColumn4,
            orderList: [],
            isScreen: false,//是否开启筛选
            isReset: false,//是否重置
            queryType: '',//请求类型
            queryEntry: '',
            showAddOrderBtn: true,//显示新增订单按钮
            ulArr: [{
                title: '创建日期',
                sort: '2'
            }, {
                title: '更新日期',
                sort: '1'
            }],
            activeScan: 0,//默认选中更新时间
            createTimeNum: 0,//创建时间
            modifyTimeNum: 0,//修改时间
            sortField: 'createDate',// 排序字段(创建时间排序: createDate,更新时间排序: lastModifyDate)
            sortOrder: 'descend',//排序规则，默认降序 (升序: ascend, 降序: descend)
            enterpriseStateInfo: {},//权限
        }
    },
    // watch:{
    //     queryType(newValue, oldValue) {
    //         console.log(`消息从 "${oldValue}" 变为 "${newValue}"`);
    //     }
    // },
    created() {
        let params = JSON.parse(this.$UrlEncode.decode(this.$route.query.key)),
            type = params.type;
           
        let listHandleScan = this.$getStorage('listHandleScan') ? this.$getStorage('listHandleScan') : '';
        if (listHandleScan) {
            let { num, value, sortField } = listHandleScan;
            if (num % 2 == 1) {
                this.ulArr[value].sort = 1
                this.sortOrder = 'ascend'
            } else {
                this.ulArr[value].sort = 2
                this.sortOrder = 'descend'
            }
            this.activeScan = value
            this.sortField = sortField
        }

        this.drawerForm = this.$getStorage('OrderListDrawerForm') ? this.$getStorage('OrderListDrawerForm') : {
                customerName: '',//客户名称名称
                orderNumber: '',//订单编号
                productName:'',//产品名称
                orderStatus:[],//订单状态
                createDateStart: '',//开始时间
                createDateEnd: '',//结束时间
        };
        let {customerName,orderNumber,productName,orderStatus,createDateStart,createDateEnd} = this.drawerForm;
        if(customerName || orderNumber || productName || orderStatus.length != 0 || createDateStart || createDateEnd){
            this.isScreen = true
        }else{
                this.isScreen = false
        }
        switch (type) {
            case 'All':
                this.headerTitle = '全部订单';
                this.findSearchAllOrderList();
                this.orderStatusList =[
                    {
                        label:'未发货',
                        value:'a1'
                    },{
                        label:'已发货',
                        value:'a2'
                    },{
                        label:'已收货',
                        value:'a3'
                    },{
                        label:'已付款',
                        value:'c1'
                    },{
                        label:'已取消',
                        value:'c2'
                    }
                ] 
                break;
        }
        this.queryType = type;
        this.queryEntry = params.entry
        this.$setStorage('pageLayer', ['/OrderList'])
        this.$setStorage('pageLayerBack', ['/OrderList'])
        this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo')
    },
    methods: {
        back() {
            if (this.queryEntry == 1 || this.headerTitle == '全部订单') {
                this.$router.push('/SupplyIndex')
            } else {
                this.$common.DelayedBack(0);
            }
            this.$removeStorage('fetchDataPage')
            this.$removeStorage('fetchDataSize')
            this.$removeStorage('OrderListDrawerForm')
            this.$removeStorage('listHandleScan')
        },
        HandleScan(value) {
            this.activeScan = value;
            switch (value) {
                case 0:
                    this.createTimeNum++;
                    this.sortField = 'createDate';
                    if (this.createTimeNum % 2 == 1) {
                        this.ulArr[value].sort = 1
                        this.sortOrder = 'ascend'
                    } else {
                        this.ulArr[value].sort = 2
                        this.sortOrder = 'descend'
                    }
                    this.$setStorage('listHandleScan', { num: this.createTimeNum, value, sortField: this.sortField })
                    break;
                case 1:
                    this.modifyTimeNum++;
                    this.sortField = 'lastModifyDate';
                    if (this.modifyTimeNum % 2 == 1) {
                        this.ulArr[value].sort = 1
                        this.sortOrder = 'ascend'
                    } else {
                        this.ulArr[value].sort = 2
                        this.sortOrder = 'descend'
                    }
                    this.$setStorage('listHandleScan', { num: this.modifyTimeNum, value, sortField: this.sortField })
                    break;
            }
            this.paging = 1;
            this.pagingSize = 10
            this.orderList = []
            this.getDate()
        },

        getDate() {
            this.findSearchAllOrderList(this.paging, this.pagingSize);
        },
        // 获取全部、已完成、未完成订单列表
        findSearchAllOrderList(page = this.page, size = this.size) {
            let that = this, { sortField, sortOrder } = that,
                { customerName, createDateStart, createDateEnd, orderNumber, productName, orderStatus } = that.drawerForm;
            that.$http.findSearchAllOrderList({
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                customerName,
                createDateStart,
                createDateEnd,
                orderNumber,
                productName,
                orderStatus,
                createUserId: '',
                orderTypeStatus: that.orderTypeStatus,
                sortField,
                sortOrder,
                page,
                size,
                orderDetailsListStatus: 'Y'
            }).then(res => {
                res.data.records.forEach(item => {
                    item.orderStatusText = that.$orderClass.getOrderState(item.orderStatus);
                    item.orderStatusColor = that.$orderClass.getOrderTextColor(item.orderStatus);
                    item.orderStatusbgColor = that.$orderClass.getorderBackgroundColor(item.orderStatus);
                })
                that.orderList = res.data.records;
                that.total = res.data.total;
                that.currentPage = res.data.current;
            })
        },
        // 提交记录筛选
        submitFilter() {
            let that = this;
            let {customerName,orderNumber,productName,orderStatus,createDateStart,createDateEnd} = that.drawerForm;
            if(customerName || orderNumber || productName || orderStatus.length != 0 || createDateStart || createDateEnd){
                this.isScreen = true
            }else{
                this.isScreen = false
            }
            that.queryType == 'ReturnOrder' ? that.findSearchOrderReturnList() : that.findSearchAllOrderList();
            // console.log(that.drawerForm);
            that.$setStorage('OrderListDrawerForm', that.drawerForm);
            that.drawer = false;
        },
        // 重置记录筛选
        resetForm() {
            // this.$refs[formName].resetFields();
            this.drawerForm = {
                customerName: '',//客户名称名称
                orderNumber: '',//订单编号
                productName:'',//产品名称
                orderStatus:[],//订单状态
                createDateStart: '',//开始时间
                createDateEnd: '',//结束时间
            };
            // this.isReset = true;
        },
        // 关闭记录筛选
        handleClose() {
            this.drawer = false
        },
        // 订单详情
        OrderDetail(orderId, orderStatus, orderReturnId) {
            let that = this,
                orderDetailStatus = '',
                type = that.queryType;
            if (type == 'ReturnOrder') {
                that.$router.push({
                    path: '/ReturnOrderDetails',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            id: orderReturnId
                        }))
                    }
                })
            } else {
                if (type == 'All') {
                    switch (orderStatus) {
                        case 'Ready':
                        case 'Deliver':
                        case 'Delivery':
                            orderDetailStatus = 'Active';//进行中订单模块列表
                            break;
                        case 'Completed':
                        case 'Canceled':
                            orderDetailStatus = 'Completed';//已完成订单模块列表
                            break;
                    }
                } else if (type == 'InProgress') {
                    orderDetailStatus = 'Active';//进行中
                } else if (type == 'Completed') {
                    orderDetailStatus = 'Completed';//已完成
                }
                that.$router.push({
                    name: 'OrderDetails',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            id: orderId, orderStatus: orderDetailStatus
                        }))
                    }
                })
            }
        },
    },
}

</script>
<style lang='less' scoped>
@import '../style/drawerBox.less';

.OrderList {
    .main {
        width: 980px;

        .banner {
            background: url('../../../assets/supply/supplyBanner.png');
            height: 100px;
            color: #FFFFFF;
            font-size: 30px;
            line-height: 100px;
            text-align: center;
            margin-bottom: 40px;
        }


        .screen-box {
            margin: 20px 0;

            .addBtn {
                width: 120px;
                height: 50px;
                background: url('../../../assets/supply/ordersNew.png');
            }

            .selectionBtn {
                width: 120px;
                height: 50px;
                background: url('../../../assets/supply/orderFiltering.png');
                cursor: pointer;
                margin: 0 25px;
            }

            .statisticsBtn {
                width: 120px;
                height: 50px;
                background: url('../../../assets/supply/orderStatistics.png');
            }
        }

        .linearGradient {
            background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
            color: #FFF;
            font-weight: 500;
            font-size: 16px;
        }

        .bgEFF4FA {
            background: #EFF4FA;
            color: #333;
            font-weight: 500;
            font-size: 16px;
        }

        .order-item {
            width: 99.9%;
            margin-bottom: 30px;
            border: 5px;

            .textVal {
                width: 45%;
                font-size: 16px;
                color: #999;
                font-weight: 400;
                margin-bottom: 15px;

                .textValSpan {
                    color: #666;
                }
            }

            .textVal span {
                color: #999;
            }

            .talTit {
                background: #EFF4FA;
                border-radius: 5px 5px 0px 0;

                span {
                    width: calc(100% / 6);
                    text-align: center;
                    line-height: 60px;
                    color: #333;
                    font-weight: 600;
                    font-size: 18px;
                }
            }

            .talVal {
                height: 60px;
                background: #fff;
                border: 1px solid #EDEDED;

                span {
                    width: calc(100% / 6);
                    text-align: center;
                    line-height: 60px;
                    color: #333;
                    font-weight: 400;
                    font-size: 16px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .order-item:hover {
            background-color: aliceblue;
            cursor: pointer;
        }

    }









    .screen-box {
        margin: 20px 0;

        .screen {
            margin: 0 25px;
        }



    }


    /deep/ .el-divider--horizontal {
        margin: 15px 0 15px;
    }

    /deep/ .el-divider__text {
        background: transparent;
    }
}
</style>